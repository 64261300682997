import React from 'react';
import linkIcon from './projects/link.png';
import githubIcon from './projects/github.png';
import ytbIcon from './projects/youtube.png';

const Project = ({ name, description, tags, links }) => {
    return (
        <>
            <a target='_blank' rel="noreferrer" className='project project-box'>
                <div>
                    <h4>{name}</h4>
                    <p className='project__description'>{description}</p>
                </div>
                <div>
                    <div className='project__tags'>
                        {
                            tags.map(tag => <span>#{tag}</span>)
                        }
                    </div>
                    <div className='project__links'>
                        {
                            links[0] ?
                            <a href={links[0]} target='_blank' rel="noreferrer"><img src={githubIcon} alt='github icon' /></a> :
                            null
                        }
                        {
                            links[2] ?
                            <a href={links[2]} target='_blank' rel="noreferrer"><img src={ytbIcon} alt='link icon' /></a> :
                            null
                        }
                        {
                            links[1] ?
                            <a href={links[1]} target='_blank' rel="noreferrer"><img src={linkIcon} alt='link icon' /></a> :
                            null
                        }
                    </div>
                </div>
            </a>
        </>
    )
}

export default Project;