import React from 'react';
import Project from './project';

const Projects = () => {
    return (
        <section className='projects-container'>
            <h3>Projects</h3>
            <div className='projects'>
                <Project
                    name='Organize Me'
                    description='Organizer App, includes a Schedule, Task tracker, Notes taker...'
                    tags={['react', 'TypeScript', 'django', 'DRF', 'tailwind']}
                    links={['', 'https://organize-me-production.up.railway.app/']}
                />
            
                <Project
                    name='Icon Shop'
                    description='E-Commerce app supports authentication, payment, add reviews...'
                    tags={['react', 'django', 'DRF', 'bootstrap', 'scss', 'redux']}
                    links={['', 'https://web-production-0371.up.railway.app/']}
                />

                <Project
                    name='Blog App'
                    description='Create, read, and add feedbacks on blogs'
                    tags={['react', 'django', 'scss', 'redux']}
                    links={['https://github.com/khalidfasely/blog', 'https://web-production-a319.up.railway.app/', 'https://www.youtube.com/embed/rQP0tVjmzio']}
                />

                <Project
                    name='Bug Tracker'
                    description='Track all bugs on your projects with your team'
                    tags={['react', 'django', 'scss', 'redux']}
                    links={['https://github.com/khalidfasely/bugtracker', 'https://web-production-2e70.up.railway.app/', "https://www.youtube.com/embed/VvX9cZaEcfQ"]}
                />

                <Project
                    name='Network App'
                    description='App to follow your friends and share your taughts with them'
                    tags={['react', 'django', 'scss', 'redux']}
                    links={['https://github.com/khalidfasely/network', 'https://web-production-e8eb.up.railway.app/', "https://www.youtube.com/embed/MD_qNKcBNSc"]}
                />

                <Project
                    name='Budget App'
                    description='Track all your expenses with details'
                    tags={['react', 'firebase', 'scss', 'redux']}
                    links={['https://github.com/khalidfasely/expensify_app', 'https://expensifyapp-production.up.railway.app/', "https://www.youtube.com/embed/qglLvi92sC0"]}
                />

                <Project
                    name='Tasks App'
                    description='Get your life under control by tracking all your tasks for the day'
                    tags={['react', 'firebase', 'scss', 'redux']}
                    links={['https://github.com/khalidfasely/todo-list', 'https://todo-list-production-972e.up.railway.app/', "https://www.youtube.com/embed/_1uA39uvJPw"]}
                />

                <Project
                    name='Mail App'
                    description='Get contact with your coworkers by mails'
                    tags={['django', 'javascript', 'css']}
                    links={['https://github.com/khalidfasely/mail', 'https://web-production-aab5.up.railway.app/']}
                />

            </div>
        </section>
    )
}

export default Projects;