import React from 'react';

import htmlIcon from "./lang-icons/html.png";
import cssIcon from "./lang-icons/css.png";
import tailwindIcon from "./lang-icons/tailwind.png";
import scssIcon from "./lang-icons/sass.png";
import javascriptIcon from "./lang-icons/javascript.png";
import typescriptIcon from "./lang-icons/typescript.png";
import reactIcon from "./lang-icons/react.png";
import djangoIcon from "./lang-icons/django.png";
import firebaseIcon from "./lang-icons/firebase.png";
import sqliteIcon from "./lang-icons/sqlite.png";
import gitIcon from "./lang-icons/git.png";
import pythonIcon from "./lang-icons/python.png";
import reduxIcon from "./lang-icons/redux.png";
import jestIcon from "./lang-icons/jest.png";

const ToolsSection = () => {
    return (
        <div className='tools-container'>
            <h3>Skills & Tools</h3>
            <div className='tools'>
                <div className='tools__item'>
                    <img src={htmlIcon} alt='html icon' />
                    <p>html</p>
                </div>
                <div className='tools__item'>
                    <img src={cssIcon} alt='css icon' />
                    <p>css</p>
                </div>
                <div className='tools__item'>
                    <img src={scssIcon} alt='scss icon' />
                    <p>scss</p>
                </div>
                <div className='tools__item'>
                    <img src={tailwindIcon} alt='tailwind icon' />
                    <p>tailwind</p>
                </div>
                <div className='tools__item'>
                    <img src={javascriptIcon} alt='javascript icon' />
                    <p>javascript</p>
                </div>
                <div className='tools__item'>
                    <img src={typescriptIcon} alt='typescript icon' />
                    <p>typescript</p>
                </div>
                <div className='tools__item'>
                    <img src={reactIcon} alt='react icon' />
                    <p>react</p>
                </div>
                <div className='tools__item'>
                    <img src={reduxIcon} alt='redux icon' />
                    <p>redux</p>
                </div>
                <div className='tools__item'>
                    <img src={jestIcon} alt='jest icon' />
                    <p>jest</p>
                </div>
                <div className='tools__item'>
                    <img src={pythonIcon} alt='python icon' />
                    <p>python</p>
                </div>
                <div className='tools__item'>
                    <img src={djangoIcon} alt='django icon' />
                    <p>django</p>
                </div>
                <div className='tools__item'>
                    <img src={sqliteIcon} alt='sqlite icon' />
                    <p>sqlite</p>
                </div>
                <div className='tools__item'>
                    <img src={firebaseIcon} alt='firebase icon' />
                    <p>firebase</p>
                </div>
                <div className='tools__item'>
                    <img src={gitIcon} alt='git icon' />
                    <p>git</p>
                </div>
            </div>
        </div>
    );
};

export default ToolsSection;

//<div className='tools__item'>
//    <img src={githubIcon} alt='github icon' />
//    <p>github</p>
//</div>