import React, { useEffect } from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import FirstSection from "../components/homeFirstSection";
import Projects1 from "../components/projects1";
import ToolsSectionSec from "../components/tools2";
import Head from "../components/head";
import { skillsAnimate } from "../styles/style";

import '../styles/index.scss';
//Learn React, React Workshops, Testing JavaScript Training, React Training, Learn JavaScript, Learn TypeScript
const Home = () => {
  useEffect(() => {
    skillsAnimate();
  }, [])
  return (
      <>
        {/*<GatsbySeo
          //description='As a Jr. Full Stack Web Developer, I specialize in ReactJS, Django, and Tailwind for web development. Check out my portfolio to see my latest projects and how I apply my skills in building user-friendly, responsive and dynamic web applications.'
          //key='Learn React, React Workshops, Testing JavaScript Training, React Training, Learn JavaScript, Learn TypeScript'
//          metaTags='Learn React, React Workshops, Testing JavaScript Training, React Training, Learn JavaScript, Learn TypeScript'
        /> */}
        <Layout>
          <Head
            //title='Home'
            //description='As a Jr. Full Stack Web Developer, I specialize in ReactJS, Django, and Tailwind for web development. Check out my portfolio to see my latest projects and how I apply my skills in building user-friendly, responsive and dynamic web applications.'
            description="I am Khalid El Fasely a junior Full-stack web developer based in Morocco. Come check out how I can help you build a web application for your business."
            //keywords="Learn React, React Workshops, Testing JavaScript Training, React Training, Learn JavaScript, Learn TypeScript"
          />
          <Link className='up-button' to='/'>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-up" className="svg-inline--fa fa-arrow-up fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"></path></svg>
          </Link>
          <FirstSection />
          <Projects1 />
          <ToolsSectionSec />
        </Layout>
      </>
      );
};

//<div className='home__img-container'>
//  <svg className='home__blob' viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
//    <path fill="#4285F4" d="M45.7,-29C61.4,-16.8,77.8,2.4,75.1,18.4C72.4,34.4,50.7,47.2,28.3,57.4C6,67.6,-16.9,75.1,-35.8,68.4C-54.8,61.6,-69.8,40.5,-72,19.6C-74.2,-1.2,-63.5,-21.7,-49.4,-33.6C-35.3,-45.5,-17.6,-48.8,-1.3,-47.8C15,-46.7,30,-41.3,45.7,-29Z" transform="translate(100 100)" />
//  </svg>
//  <img className='home__img' src={photo} alt="me" />
//</div>

export default Home;