import React from 'react';
import { Link } from 'gatsby';
import SocialMedia from './social-media';

import photo from "./projects/programming.svg";

const FirstSection = () => {
    return (
        <div className='home'>
          <div className="home__infos">
            <div className='home__infos-left'>
              <h4>Hey! I'm <span>Khalid</span> 👋</h4>
              <h1>I'm a Junior Full-stack Developer</h1>
              <h2>Trying to make the world a better place by building{/* creating */} web apps.</h2>
              {/*<p>I build web sites & apps.</p>*/}
            </div>
            <SocialMedia />
            <Link className="home__contact-me-link" to='/contact'>
              <span>Contact me</span>
              <span className='arrow'>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-right" className="svg-inline--fa fa-angle-double-right fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z"></path></svg>
              </span>
            </Link>
          </div>
          <div className='home__img-container'>
            <img src={photo} alt='programmer illustration' />
          </div>
        </div>
    );
};

export default FirstSection;

//<p>Hey! I'm <span>Khalid</span></p>
//<h1>I'm a Full-stack Developer</h1>
//<p>I build web sites & apps.</p>